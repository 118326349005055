<template>
  <div>
    <div class="editable" :class="noHeight ? 'noHeight' : 'pos'">
      <h4 class="title" v-if="showTitle">
        <span> {{ title }} </span>
      </h4>
      <div v-if="editor && showOptions">
        <bubble-menu
          class="bubble-menu"
          :tippy-options="{ duration: 100 }"
          :editor="editor"
        >
          <span
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          >
            <i
              :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
              class="fas fa-heading editIcon"
            >
            </i>
          </span>

          <span @click="editor.chain().focus().toggleBold().run()">
            <i
              :class="{ 'is-active': editor.isActive('bold') }"
              class="fas fa-bold editIcon"
            ></i>
          </span>

          <span @click="editor.chain().focus().toggleItalic().run()">
            <i
              :class="{ 'is-active': editor.isActive('italic') }"
              class="fas fa-italic editIcon"
            >
            </i>
          </span>

          <span @click="editor.chain().focus().toggleBulletList().run()">
            <i
              :class="{ 'is-active': editor.isActive('bulletList') }"
              class="fas fa-list editIcon"
            >
            </i>
          </span>

          <span @click="editor.chain().focus().toggleOrderedList().run()">
            <i
              :class="{ 'is-active': editor.isActive('orderedList') }"
              class="fas fa-list-ol editIcon"
            >
            </i>
          </span>

          <span @click="setLink">
            <i
              :class="{ 'is-active': editor.isActive('link') }"
              class="fas fa-link editIcon"
            >
            </i>
          </span>
        </bubble-menu>

        <!-- <floating-menu
          class="floating-menu"
          :tippy-options="{ duration: 100 }"
          :editor="editor"
        >
          <button
            @click="editor.chain().focus().toggleBulletList().run()"
            :class="{ 'is-active': editor.isActive('bulletList') }"
          >
            List
          </button>
        </floating-menu> -->
      </div>

      <editor-content :editor="editor" />

      <div class="footer" v-if="!disabled">
        <div v-show="!hideAIGenerate">
          <c-link @click="isTeamDrawerOpen = !isTeamDrawerOpen">
            <c-tag size="md" variant-color="red" mx="2">AI Generate</c-tag>
          </c-link>
        </div>
        <div v-show="!hideAISuggest">
          <c-link @click="isAiModalOpen = !isAiModalOpen">
            <c-tag size="md" variant-color="red" mx="2">AI Suggest</c-tag>
          </c-link>
        </div>
      </div>
    </div>
    <TeamDrawer
      :title="title"
      :context="value"
      :type="type"
      :isOpen="isTeamDrawerOpen"
      @close="closeTeamDrawer"
      v-if="isTeamDrawerOpen"
    />
    <OpenAIModal
      :title="title"
      :context="value"
      :isOpen="isAiModalOpen"
      @close="closeAiModal"
      v-if="isAiModalOpen"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TeamDrawer from '../../../App/components/OpenAiDrawer.vue';
import OpenAIModal from '../../../App/components/OpenAiModal.vue';

// import Collaboration from '@tiptap/extension-collaboration';
// import { HocuspocusProvider } from '@hocuspocus/provider';
// import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
// import * as Y from 'yjs';
import Placeholder from '@tiptap/extension-placeholder';
import Link from '@tiptap/extension-link';
import { BubbleMenu, Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
// import { WebrtcProvider } from 'y-webrtc';

export default {
  components: {
    TeamDrawer,
    OpenAIModal,
    EditorContent,
    BubbleMenu,
  },
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    type: {
      type: String,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    editabelIndex: {
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Click to edit ...',
    },
    noHeight: {
      type: Boolean,
      default: false,
    },
    showOptions: {
      type: Boolean,
      default: true,
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  beforeUnmount() {
    this.editor.destroy();
    this.provider.destroy();
  },
  data() {
    return {
      isTeamDrawerOpen: false,
      isAiModalOpen: false,
      editor: null,
      provider: null,
      text: '',
      initialContent: '',
    };
  },
  watch: {
    value(value) {
      if (this.editor) {
        const isSame = this.editor.getHTML() === value;
        if (isSame) {
          return;
        }
        this.text = value;
        this.editor.commands.setContent(value);
      }
    },
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    isStaging() {
      return (
        window.location.hostname.startsWith('develop') ||
        window.location.hostname.startsWith('localhost')
      );
    },
    hideAIGenerate() {
      return (
        this.title == 'Customer Description' ||
        this.title == 'channels' ||
        this.title == 'Key Resources' ||
        this.title === 'Key Partners' ||
        this.title == 'Customer Edit' ||
        this.title == 'Revenue' ||
        this.title == 'Ideal Culture' ||
        this.title == 'Importance Level' ||
        this.title == 'Markets' ||
        this.title == 'Competition Value' ||
        this.title === 'Cost' ||
        !this.title ||
        this.title == 'Values' ||
        this.title == 'Generate' ||
        this.title == 'Pitch' ||
        this.title == 'Key Activities'
      );
    },
    hideAISuggest() {
      return (
        this.title == 'Revenue' ||
        this.title == 'How do you contribute to reach these goals?' ||
        this.title == 'Competition Value' ||
        this.title === 'Cost' ||
        !this.title ||
        this.title == 'Markets' ||
        this.title == 'Values' ||
        this.title == 'channels' ||
        this.title == 'Key Resources' ||
        this.title === 'Key Partners' ||
        this.title == 'Key Activities'
      );
    },
  },
  mounted() {
    // const ydoc = new Y.Doc();

    // Set up the Hocuspocus WebSocket provider
    // this.provider = new HocuspocusProvider({
    //   url: 'ws://vibrantcreator-temp-lib-dev.herokuapp.com',
    //   name: this.type + this.activeCompany.id,
    //   document: ydoc,
    // });

    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit.configure({
          history: false,
        }),
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
        Link.configure({
          openOnClick: true,
        }),
        // Collaboration.configure({
        //   document: this.provider.document,
        // }),
        // CollaborationCursor.configure({
        //   provider: this.provider,
        //   user: {
        //     name: this.user.firstname,
        //     color: '#f783ac',
        //   },
        // }),
      ],
      onBlur: function ({ editor }) {
        if (!this.value) {
          this.closeTeamDrawer(editor.getHTML());
          return;
        }
        const editorHTML = editor.getHTML().replace(/\s/g, '');
        const valueHTML = this.value?.replace(/\s/g, '');
        const isSame = editorHTML === valueHTML;
        // JSON
        // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

        if (isSame) {
          return;
        } else {
          this.closeTeamDrawer(editor.getHTML());
        }
      }.bind(this),
    });

    // wait for 3 seconds
    // setTimeout(() => {
    //   // check if editor is empty
    //   const elementsWithEmptyClass =
    //     document.querySelectorAll('.is-editor-empty');

    //   if (elementsWithEmptyClass.length > 0) {
    //     this.editor.commands.setContent(this.value);
    //   }
    // }, 3000);
  },
  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href;
      const url = window.prompt('URL', previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run();

        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    },
    closeTeamDrawer(results) {
      if (!results) {
        this.isTeamDrawerOpen = false;
        return;
      } else {
        this.sentResults(results);
      }
    },
    closeAiModal(results) {
      // check if results is empty or null or undefined
      if (!results) {
        this.isAiModalOpen = false;
        return;
      } else {
        this.sentResults(results);
      }
    },
    sentResults(results) {
      if (typeof results === 'object' && this.editabelIndex == null) {
        this.text = results.description;
        this.$emit('updateDescription', results);
        this.isTeamDrawerOpen = false;
        this.isAiModalOpen = false;
      }

      if (typeof results === 'object' && this.editabelIndex !== null) {
        this.text = results.description;
        this.$emit('updateDescription', results, this.editabelIndex);
        this.isTeamDrawerOpen = false;
        this.isAiModalOpen = false;
      }

      if (typeof results !== 'object' && this.editabelIndex == null) {
        this.content = results;
        this.$emit('updateDescription', results);
        this.isTeamDrawerOpen = false;
        this.isAiModalOpen = false;
      } else if (typeof results !== 'object' && this.editabelIndex !== null) {
        this.content = results;
        this.$emit('updateDescription', results, this.editabelIndex);
        this.isTeamDrawerOpen = false;
        this.isAiModalOpen = false;
      } else {
        this.content = results;
        this.$emit('updateDescription', results);
        this.isTeamDrawerOpen = false;
        this.isAiModalOpen = false;
      }
    },
  },
};
</script>

<style lang="scss">
.editable {
  @apply w-full max-w-full flex flex-col mb-3 relative;

  .title {
    @apply text-lg font-semibold flex justify-between;
    color: #495057;
  }

  p {
    @apply text-sm;
  }
}
.footer {
  @apply w-full flex justify-end;
}

.noHeight .ProseMirror {
  max-height: none;
}
.pos .ProseMirror {
  min-height: 50px;
  max-height: 160px;
  overflow: scroll;
}

.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  a {
    color: #68cef8;
  }

  // ul,
  // ol {
  //   padding: 0 1rem;
  // }

  // blockquote {
  //   padding-left: 1rem;
  //   border-left: 2px solid rgba(#0d0d0d, 0.1);
  // }
}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.bubble-menu {
  display: flex;
  background-color: whitesmoke;
  padding: 7px 0px 7px 7px;
  border-radius: 0.5rem;
}

.floating-menu {
  display: flex;
  background-color: #0d0d0d10;
  padding: 0.2rem;
  border-radius: 0.5rem;
  margin: 10px;

  button {
    border: none;
    background: none;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}

.editIcon {
  margin-right: 10px;
  color: grey;

  &:hover,
  &.is-active {
    opacity: 1;
    color: black;
  }
}

/* Give a remote user a caret */
.collaboration-cursor__caret {
  position: relative;
  margin-left: -1px;
  margin-right: -1px;
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  word-break: normal;
  pointer-events: none;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  position: absolute;
  top: -1.4em;
  left: -1px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  user-select: none;
  color: #0d0d0d;
  padding: 0.1rem 0.3rem;
  border-radius: 3px 3px 3px 0;
  white-space: nowrap;
}
</style>
